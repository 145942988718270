import React, { useState } from "react";
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import './index.css';
import Modal from 'react-bootstrap/Modal';
import { partnersService } from '../../services/partners';
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment';
import { scheduleService } from '../../services/schedule';
const acesso = localStorage.getItem("tipoAcesso");

class Home extends React.Component {
    state = {
        datatable: [],
        show: false,
        message: '',
        dataDe: '',
        dataAte: '',
        modalShow: false,
        partnerList: [],
        idParceiro: '',
        nomeParceiro: '',
        modalShowPaciente: false,
        modalShowParceiro: false
    };

    componentDidMount() {
        this.fetch();
    }

    generateReportPatient = async () => {
        try {
            if (this.validate()) {

                const { dataAte, dataDe, idParceiro } = this.state;
                const doc = new jsPDF({
                    orientation: 'landscape',
                });
                const dataAg = moment().format('YYYYMMDD');
                let datatable = []
                const partner = idParceiro ? `AND us.idUsuario = ${idParceiro}` : '';
                const { data } =
                    await scheduleService.getReportPatient
                        ({ filter: `dataAgendamento BETWEEN '${moment(dataDe).format('YYYYMMDD')}' AND '${moment(dataAte).format('YYYYMMDD')}' ${partner}` });
                let valorTotal = 0;

                if (data && data.length <= 0)
                    throw new Error('Não foram encontrados registros!');

                for (let index = 0; index < data.length; index++) {
                    const { Nome_Paciente, Nome_Parceiro, dataAgendamento, entrada, saida, Ativo, Email, ValorHora, hourDiff, ValorUtilizado } = data[index];
                    valorTotal += ValorUtilizado;
                    datatable.push([Nome_Parceiro, Nome_Paciente, dataAgendamento, entrada, saida, Ativo, Email, ValorHora, hourDiff, ValorUtilizado,]);
                }

                datatable.push(['Parceiro', 'Paciente', 'Data', "Entrada", "Saida", "Ativo?", "Email", "Valor/Hora", "Tempo Utilizado", "Valor Total", valorTotal]);

                autoTable(doc, {
                    head: [['Parceiro', 'Paciente', 'Data', "Entrada", "Saida", "Ativo?", "Email", "Valor/Hora", "Tempo Utilizado", "Valor Total", "Soma Total"]],
                    body: datatable,
                })

                doc.save(`${dataAg}_Relatorio.pdf`)
            }
        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    };
    timeToMinutes = (timeString) => {
        const [hours, minutes] = timeString.split(':').map(Number);
        return hours * 60 + minutes;
    }
    generateReportPartner = async () => {
        try {
            debugger
            if (this.validate()) {

                const { dataAte, dataDe, idParceiro } = this.state;
                const doc = new jsPDF({
                    orientation: 'landscape',
                });
                const dataAg = moment().format('YYYYMMDD');
                let datatable = []
                const partner = idParceiro ? `AND us.idUsuario = ${idParceiro}` : '';
                const { data } =
                    await scheduleService.getReportPartner
                        ({ filter: `dataChegada BETWEEN '${moment(dataDe).format('YYYYMMDD')}' AND '${moment(dataAte).format('YYYYMMDD')}' ${partner}` });
                let valorTotal = 0;
                let horasTotais = 0;
                if (data && data.length <= 0)
                    throw new Error('Não foram encontrados registros!');

                for (let index = 0; index < data.length; index++) {
                    const { Nome_Parceiro, dataChegada, sala, entrada, saida, Ativo, Email, ValorHora, hourDiff, ValorUtilizado } = data[index];
                    valorTotal += ValorUtilizado;
                    horasTotais += this.timeToMinutes(hourDiff);
                    datatable.push([Nome_Parceiro, sala, dataChegada, entrada, saida, Ativo, Email, ValorHora, hourDiff, null, null, ValorUtilizado,]);
                }

                const totalHours = Math.floor(horasTotais / 60);
                const totalMinutes = horasTotais % 60;

                // Garantir que os minutos sejam inteiros e formatar com dois dígitos
                const formattedTime = `${String(totalHours).padStart(2, '0')}:${String(Math.floor(totalMinutes)).padStart(2, '0')}`;

                datatable.push(['Parceiro', 'Sala', 'Data', "Entrada", "Saida", "Ativo?", "Email", "Valor/Hora", `Tempo Total`, formattedTime, "", valorTotal]);

                autoTable(doc, {
                    head: [['Parceiro', 'Sala', 'Data', "Entrada", "Saida", "Ativo?", "Email", "Valor/Hora", "Tempo Utilizado", "Total", "", "Soma Total"]],
                    body: datatable,
                })

                doc.save(`${dataAg}_Relatorio.pdf`)
            }
        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    };

    cleanFilters = () => {
        this.setState({
            nomeParceiro: '',
            idParceiro: '',
            dataDe: '',
            dataAte: '',
        });
    }

    validate = () => {
        try {

            if (!this.state.dataAte)
                throw new Error('Data de inicio Vazia');
            if (!this.state.dataDe)
                throw new Error('Data final Vazia');

            return true;
        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
            return false;
        }
    }

    fetch = async () => {
        try {
            let responsePartner = await partnersService.get();
            this.setState({
                partnerList: responsePartner.data
            });
        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    };

    render() {
        const { nomeParceiro, idParceiro } = this.state;

        const CustomMenu = React.forwardRef(
            ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
                const [value, setValue] = useState('');

                return (
                    <div ref={ref} style={style} className={className} aria-labelledby={labeledBy}>
                        <Form.Control
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Filtrar"
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                        <ul className="list-unstyled">
                            {React.Children.toArray(children).filter(
                                (child) => !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                            )}
                        </ul>
                    </div>
                );
            },
        );

        return (
            <Container fluid className="custom-background">
                <div className="Alert">
                    <Alert show={this.state.show} variant="info" dismissible onClose={() => this.setState({ show: false })}>
                        {this.state.message}
                    </Alert>
                </div>

                {/* Centralizando o título "Bem vindo" */}
                <Row className="justify-content-center text-center" style={{ margin: 30 }}>
                    <h3>{`Bem vindo ${localStorage.getItem("userId")}, por onde vamos começar?`}</h3>
                </Row>

                {/* Cards estilizados e centralizados */}
                <Row className="d-flex justify-content-center">
                    {acesso >= 3 && (
                        <Card className="custom-card text-center">
                            <Card.Body>
                                <Card.Title>Usuários</Card.Title>
                                <Card.Text>Gestor dos usuários do sistema.</Card.Text>
                                <Button variant="outline-light" href="/partner">Acessar</Button>
                            </Card.Body>
                        </Card>
                    )}
                    {acesso >= 2 && (
                        <Card className="custom-card text-center">
                            <Card.Body>
                                <Card.Title>Pacientes</Card.Title>
                                <Card.Text>Gestor de cadastro de pacientes.</Card.Text>
                                <Button variant="outline-light" href="/patient">Acessar</Button>
                            </Card.Body>
                        </Card>
                    )}
                    {acesso >= 1 && (
                        <Card className="custom-card text-center">
                            <Card.Body>
                                <Card.Title>Agenda</Card.Title>
                                <Card.Text>Agenda de entrada e saída de pacientes.</Card.Text>
                                <Button variant="outline-light" href="/schedule">Acessar</Button>
                            </Card.Body>
                        </Card>
                    )}
                    {acesso >= 1 && (
                        <Card className="custom-card text-center">
                            <Card.Body>
                                <Card.Title>Ponto Entrada</Card.Title>
                                <Card.Text>Relatorio de entrada e saída dos parceiros.</Card.Text>
                                <Button variant="outline-light" href="/Partnerschedule">Acessar</Button>
                            </Card.Body>
                        </Card>
                    )}
                    {acesso >= 3 && (
                        <Card className="custom-card text-center">
                            <Card.Body>
                                <Card.Title>Relatório Pacientes</Card.Title>
                                <Card.Text>Gere relatórios detalhados.</Card.Text>
                                <Button variant="outline-light" onClick={() => this.setState({ modalShowPaciente: true })}>Acessar</Button>
                            </Card.Body>
                        </Card>
                    )}
                    {acesso >= 3 && (
                        <Card className="custom-card text-center">
                            <Card.Body>
                                <Card.Title>Relatório Parceiros</Card.Title>
                                <Card.Text>Gere relatórios detalhados.</Card.Text>
                                <Button variant="outline-light" onClick={() => this.setState({ modalShowParceiro: true })}>Acessar</Button>
                            </Card.Body>
                        </Card>
                    )}
                </Row>

                <Modal show={this.state.modalShowPaciente} onHide={() => { this.setState({ modalShowPaciente: false }) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filtros</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{ textAlign: "center" }}>
                            <Col style={{ padding: 15 }}>
                                <h6>De</h6>
                                <Form.Control style={{ marginTop: 5 }} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                    value={this.state.dataDe} type="date"
                                    onChange={item => this.setState({ dataDe: item.target.value })} />
                            </Col>
                            <Col style={{ padding: 15 }}>
                                <h6>Até</h6>
                                <Form.Control style={{ marginTop: 5 }} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                    value={this.state.dataAte} type="date"
                                    onChange={item => this.setState({ dataAte: item.target.value })} />
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center" }}>
                            <h6>Colaborador</h6>
                            <Dropdown onSelect={(id, name) => {
                                this.setState({ nomeParceiro: name.target.text, idParceiro: id });
                            }} style={{ marginTop: 10 }} placeholder="Parceiro" >
                                <Dropdown.Toggle id="dropdown-custom-components" style={{ width: '100%' }}>
                                    {`${idParceiro} - ${nomeParceiro}`}
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu}  >
                                    {this.state.partnerList && this.state.partnerList.map(x => {
                                        return <Dropdown.Item eventKey={x.idUsuario}>{`${x.Nome} ${x.Sobrenome}`}</Dropdown.Item>
                                    })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ modalShowPaciente: false })}>
                            Sair
                        </Button>
                        <Button variant="danger" onClick={() => this.cleanFilters()}>
                            Limpar
                        </Button>
                        <Button variant="success" onClick={() => this.generateReportPatient()}>
                            Exportar
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.modalShowParceiro} onHide={() => { this.setState({ modalShowParceiro: false }) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Filtros</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row style={{ textAlign: "center" }}>
                            <Col style={{ padding: 15 }}>
                                <h6>De</h6>
                                <Form.Control style={{ marginTop: 5 }} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                    value={this.state.dataDe} type="date"
                                    onChange={item => this.setState({ dataDe: item.target.value })} />
                            </Col>
                            <Col style={{ padding: 15 }}>
                                <h6>Até</h6>
                                <Form.Control style={{ marginTop: 5 }} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                                    value={this.state.dataAte} type="date"
                                    onChange={item => this.setState({ dataAte: item.target.value })} />
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "center" }}>
                            <h6>Colaborador</h6>
                            <Dropdown onSelect={(id, name) => {
                                this.setState({ nomeParceiro: name.target.text, idParceiro: id });
                            }} style={{ marginTop: 10 }} placeholder="Parceiro" >
                                <Dropdown.Toggle id="dropdown-custom-components" style={{ width: '100%' }}>
                                    {`${idParceiro} - ${nomeParceiro}`}
                                </Dropdown.Toggle>

                                <Dropdown.Menu as={CustomMenu}  >
                                    {this.state.partnerList && this.state.partnerList.map(x => {
                                        return <Dropdown.Item eventKey={x.idUsuario}>{`${x.Nome} ${x.Sobrenome}`}</Dropdown.Item>
                                    })
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ modalShowParceiro: false })}>
                            Sair
                        </Button>
                        <Button variant="danger" onClick={() => this.cleanFilters()}>
                            Limpar
                        </Button>
                        <Button variant="success" onClick={() => this.generateReportPartner()}>
                            Exportar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}

export default Home;
