import React, { useState, useEffect } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import { FaHome, FaHouseUser, FaHospitalUser } from 'react-icons/fa';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Nav from 'react-bootstrap/Nav';
import { useStateValue } from '../../state';
import Button from 'react-bootstrap/Button';
import FormControl from 'react-bootstrap/FormControl';
import { FaUserAlt, FaPowerOff, FaCalendarAlt } from 'react-icons/fa';

const AppLayout = ({ component: Component, ...rest }) => {

    const menu = [
        { path: "/", icon: <FaHome />, title: "Inicio", visible: true, id: 0, tipoAcesso: 0 },
        { path: "/partner", icon: <FaHospitalUser />, title: "Usuarios", visible: true, id: 1, tipoAcesso: 3 },
        { path: "/patient", icon: <FaHouseUser />, title: "Pacientes", visible: true, id: 2, tipoAcesso: 2 },
        { path: "/schedule", icon: <FaCalendarAlt />, title: "Agenda", visible: true, id: 3, tipoAcesso: 1 },
        { path: "/Partnerschedule", icon: <FaCalendarAlt />, title: "Ponto Acesso", visible: true, id: 4, tipoAcesso: 1 },
    ]
    const acesso = localStorage.getItem("tipoAcesso");
    const [{ authentication }, dispatch] = useStateValue();

    return (
        <Route {...rest} render={matchProps => (
            authentication ?
                <>
                    <Navbar bg="dark" variant="dark" expand={false}>
                        <Container fluid>
                            <Navbar.Toggle aria-controls="offcanvasNavbar" />
                            <Navbar.Offcanvas
                                id="offcanvasNavbar"
                                aria-labelledby="offcanvasNavbarLabel"
                                placement="start"
                            >
                                <Offcanvas.Header closeButton style={{ background: 'white' }}>
                                    <FaUserAlt style={{ width: '45px', height: '45px', color: "#474747" }} />
                                    <Offcanvas.Title> {localStorage.getItem("userId").toUpperCase()}</Offcanvas.Title>
                                </Offcanvas.Header>
                                <Offcanvas.Body>
                                    <Nav activeKey="/" className="justify-content-start flex-grow-1 pe-3" >
                                        {(menu).map(x => {
                                            return (!(acesso >= x.tipoAcesso) ? null : <Nav.Link href={x.path} key={x.id}>{x.icon}  {x.title}</Nav.Link>)
                                        })}
                                        <Nav.Link href="login" onClick={() => dispatch({ type: 'logout' })} key="00"><FaPowerOff /> Sair</Nav.Link>
                                    </Nav>
                                    {/* <Form className="d-flex">
                                        <FormControl
                                            type="search"
                                            placeholder="Menus"
                                            className="me-2"
                                            aria-label="Search"
                                        />
                                        <Button variant="outline-success">Procurar</Button>
                                    </Form> */}
                                </Offcanvas.Body>
                            </Navbar.Offcanvas>
                            <Button variant="outline-primary" onClick={() => dispatch({ type: 'logout' })}>Sair</Button>
                        </Container>
                    </Navbar>

                    <Component {...matchProps} authentication={authentication} />


                </>
                :
                <Redirect to='/login' />
        )} />
    )
}
export default AppLayout;
