import { request } from '../http';

const pointService = {
    getByFilter: (body) => {
        return request.post('/pointControl/filter', body);
    },
    getReport: (body) => {
        return request.post('/pointControl/report', body);
    },
    post: (body) => {
        return request.post('/pointControl', body);
    },
    patch: (body, id) => {
        return request.patch(`/pointControl/${id}`, body);
    }
    ,
    delete: (id) => {
        return request.delete(`/pointControl/${id}`);
    }
}

export {
    pointService
}