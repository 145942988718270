import React from 'react';
import AppReducer from './reducers';
import { BrowserRouter as Router, Route, Redirect, Switch } from "react-router-dom";
import Home from './pages/home';
import AppLayout from './components/layout/index'
import PartnersManage from './pages/partner';
import PartientManage from './pages/patient';
import PartnerSchedule from './pages/partnerSchedule';
import Schedule from './pages/schedule';
import Login from './pages/login';
import { useCookies } from 'react-cookie';
import { StateProvider } from './state';
import './App.css';

function App() {

  const [cookies] = useCookies(['authentication']);

  const initialState = {
    authentication: cookies.authentication
  };


  return (
    <Router>
      <StateProvider initialState={initialState} reducer={AppReducer}>
        <Switch>
          <Route path="/login" component={Login} />
          <AppLayout path="/partner" component={PartnersManage} />
          <AppLayout path="/patient" component={PartientManage} />
          <AppLayout path="/schedule" component={Schedule} />
          <AppLayout path="/Partnerschedule" component={PartnerSchedule} />
          <AppLayout path="/" component={Home} />
        </Switch>
      </StateProvider>
    </Router>
  );
}

export default App;
