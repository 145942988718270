import React, { useState } from "react";
import Table from 'react-bootstrap/Table'
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import { partnersService } from '../../services/partners';
import { patientsService } from '../../services/patients';
import { scheduleService } from '../../services/schedule';
import { FaArrowLeft, FaArrowRight, FaUserEdit, FaTrashAlt } from "react-icons/fa";
import Modal from 'react-bootstrap/Modal';
import Pagination from 'react-bootstrap/Pagination';
import Dropdown from 'react-bootstrap/Dropdown';
import moment from 'moment';
import { Spinner } from '../../components/Spinner'
const acesso = localStorage.getItem("tipoAcesso");
class Schedule extends React.Component {
    state = {
        datatable: [],
        patientListCombo: [],
        show: false,
        message: '',
        modalShow: false,
        entrada: '',
        saida: '',
        dataAgendamento: '',
        idParceiro: '',
        nomeParceiro: '',
        idPaciente: '',
        nomePaciente: '',
        pages: [],
        partnerList: [],
        patientList: [],
        allPatientList: [],
        id: ''
    };

    componentDidMount() {
        this.fetch();
    }

    fetch = async () => {

        if (acesso < 1)
            window.open('/', "_self");

        try {
            let dataAg = moment().format('YYYYMMDD');
            let responsePartner = await partnersService.get();
            let responseSchedule = await scheduleService.getByFilter({ filter: `dataAgendamento = '${dataAg}'` });
            let responsePatient = await patientsService.get();

            this.setState({
                partnerList: responsePartner.data,
                dataAgendamento: moment().format('YYYY-MM-DD'),
                datatable: responseSchedule.data,
                allPatientList: responsePatient.data
            });


        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }

    };

    cancelEdit() {
        try {
            this.setState({

                modalShow: false
            });

            this.fetch();
        } catch (error) {
            this.setState({ show: true, message: error });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    }

    validateFields() {
        try {

            if (!this.state.entrada)
                throw new Error('Horario de Entrada Vazio');
            // if (!this.state.saida)
            //     throw new Error('Horario de Saída Vazio');
            if (!this.state.idParceiro)
                throw new Error('Parceiro não preenchido');
            if (!this.state.idPaciente) throw new Error('Paciente não preenchido');

            return true;
        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
            return false;
        }
    }

    delete = async (id) => {
        try {
            debugger
            scheduleService.delete(id).then(res => {

                scheduleService.getByFilter({ filter: `dataAgendamento = '${moment(this.state.dataAgendamento).format('YYYYMMDD')}'` }).then(response => {

                    this.setState({
                        show: true,
                        message: 'Agendamento excluido com sucesso',
                        datatable: response.data,
                        modalShow: false
                    });

                }).catch((response) => {

                    this.setState({ show: true, message: response.message });
                    setTimeout(() => { this.setState({ show: false }) }, 7000);
                });

            }).catch((response) => {

                this.setState({ show: true, message: response.message });
                setTimeout(() => { this.setState({ show: false }) }, 7000);
            });

        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    }

    saveOrUpdate = async () => {
        try {
            if (this.validateFields()) {

                let { idParceiro, idPaciente, entrada, saida, dataAgendamento, id } = this.state;
                let dataAg = moment(dataAgendamento).format('YYYYMMDD');
                let body = { dataAgendamento: dataAg, idParceiro, idPaciente, entrada, saida };


                if (id) {
                    var request = scheduleService.patch(body, id);
                } else {
                    var request = scheduleService.post(body);
                }
                request.then(response => {
                    if (response.status == 201) {

                        scheduleService.getByFilter({ filter: `dataAgendamento = '${dataAg}'` }).then(response => {

                            this.setState({
                                show: true,
                                message: `Agendamento ${id ? 'atualizado' : 'inserido'} com sucesso`,
                                datatable: response.data,
                                modalShow: false
                            });

                        }).catch((response) => {

                            this.setState({ show: true, message: response.message });
                            setTimeout(() => { this.setState({ show: false }) }, 7000);
                        });

                    } else {
                        this.setState({
                            show: true,
                            message: 'Não foi possivel inserir entrada!'
                        });
                    }

                    setTimeout(() => { this.setState({ show: false }) }, 7000);

                }).catch((response) => {

                    this.setState({ show: true, message: response.message });
                    setTimeout(() => { this.setState({ show: false }) }, 7000);
                });

            }

        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    }


    loadPatients(id) {

        patientsService.getByGroup(id).then(response => {
            if (response.status == 201) {

                this.setState({
                    patientList: response.data,
                });

            } else {
                this.setState({
                    show: true,
                    message: 'Não foram encontrados pacientes vinculados!'
                });

            }

            setTimeout(() => { this.setState({ show: false }) }, 7000);

        }).catch((response) => {

            this.setState({ show: true, message: response.data.responseResult });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        });
    }

    returnPartnerName(id) {
        try {
            let response = this.state.partnerList.filter(y => y.idUsuario == id);
            return `${response[0].Nome} ${response[0].Sobrenome}`;
        } catch (error) {

        }
    }

    editModal = async (idAgendamento) => {
        try {
            if (idAgendamento) {
                let { data } = await scheduleService.getByFilter({ filter: `idAgendamento = '${idAgendamento}'` });
                let data2Save = data[0];
                this.loadPatients(data2Save.idParceiro);
                this.setState({ ...data2Save, id: idAgendamento, nomeParceiro: this.returnPartnerName(data2Save.idParceiro), nomePaciente: this.returnPatientName(data2Save.idPaciente) });
            } else {
                this.setState({ id: '', nomeParceiro: '', nomePaciente: '', idPaciente: '', idParceiro: '', entrada: '', saida: '' });
            }

            this.setState({ modalShow: true });

        } catch (error) {

        }
    }

    returnPatientName(id) {
        try {
            let response = this.state.allPatientList.filter(y => y.idPacientes == id);
            return `${response[0].nome}`;
        } catch (error) {

        }
    }

    nextDay = async () => {
        try {
            let dataAg = moment(this.state.dataAgendamento).add(1, 'd').format('YYYYMMDD');
            let responseSchedule = await scheduleService.getByFilter({ filter: `dataAgendamento = '${dataAg}'` });
            this.setState({
                dataAgendamento: moment(this.state.dataAgendamento).add(1, 'd').format('YYYY-MM-DD'),
                datatable: responseSchedule.data,
            });
        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    }

    prevDay = async () => {
        try {
            let dataAg = moment(this.state.dataAgendamento).add(-1, 'd').format('YYYYMMDD');
            let responseSchedule = await scheduleService.getByFilter({ filter: `dataAgendamento = '${dataAg}'` });

            this.setState({
                dataAgendamento: moment(this.state.dataAgendamento).add(-1, 'd').format('YYYY-MM-DD'),
                datatable: responseSchedule.data,
            });
        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    }

    setDay = async (data) => {
        try {
            let dataAg = moment(data).format('YYYYMMDD');
            let responseSchedule = await scheduleService.getByFilter({ filter: `dataAgendamento = '${dataAg}'` });

            this.setState({
                dataAgendamento: data,
                datatable: responseSchedule.data,
            });
        } catch (error) {
            this.setState({ show: true, message: error.message });
            setTimeout(() => { this.setState({ show: false }) }, 7000);
        }
    }
    render() {

        const CustomMenu = React.forwardRef(
            ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
                const [value, setValue] = useState('');

                return (
                    <div
                        ref={ref}
                        style={style}
                        className={className}
                        aria-labelledby={labeledBy}
                    >
                        <Form.Control
                            autoFocus
                            className="mx-3 my-2 w-auto"
                            placeholder="Filtrar"
                            onChange={(e) => setValue(e.target.value)}
                            value={value}
                        />
                        <ul className="list-unstyled">
                            {React.Children.toArray(children).filter(
                                (child) => !value || child.props.children.toLowerCase().startsWith(value.toLowerCase()),
                            )}
                        </ul>
                    </div>
                );
            },
        );


        const { datatable, currentPage, postPerPage,
            pages, nomeParceiro, idParceiro, idPaciente, nomePaciente } = this.state;

        const indexOfLastPost = currentPage * postPerPage;
        const indexOfFirstPost = indexOfLastPost - postPerPage;

        const currentData = datatable.slice(indexOfFirstPost, indexOfLastPost);

        const nextPage = () => this.setState({ currentPage: currentPage + 1 });
        const prevPage = () => this.setState({ currentPage: currentPage - 1 });

        return (
            <Container fluid align="right">
                <div className="Alert">
                    <Alert show={this.state.show} variant="info" dismissible onClose={() => this.setState({ show: false })}>
                        {this.state.message}
                    </Alert>
                </div>
                <Row style={{ textAlign: "center" }}>
                    <Col style={{ padding: 20 }}>
                        <Button variant="primary" onClick={this.prevDay}><FaArrowLeft /></Button >
                    </Col>
                    <Col style={{ padding: 15 }}>
                        <Form.Control style={{ marginTop: 5 }} pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                            value={this.state.dataAgendamento} type="date" placeholder="Data Nascimento"
                            onChange={item => { this.setDay(item.target.value) }} />
                    </Col>
                    <Col style={{ padding: 20 }}>
                        <Button variant="primary" onClick={this.nextDay}><FaArrowRight /></Button >
                    </Col>
                </Row>
                <Row className="justify-content-md-center" style={{ textAlign: "center" }}>
                    <Col>
                        <Table responsive striped bordered hover>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>ID</th>
                                    <th>Parceiro</th>
                                    <th>Sala</th>
                                    <th>Chegada</th>
                                    <th>Saída</th>
                                    <th>Editar</th>
                                </tr>
                            </thead>
                            <tbody>

                                {this.state.datatable ? (this.state.datatable).map(x => {
                                    return <tr key={`J${x.idAgendamento}`}>
                                        <td key={`H${x.idAgendamento}DS@#`}>-</td>
                                        <td key={`E${x.idAgendamento}AS$#!@2`}>{x.idAgendamento}</td>
                                        <td key={`D${x.idAgendamento}DSA5`}>{this.returnPartnerName(x.idParceiro)}</td>
                                        <td key={`D${x.idAgendamento}SADA435`}>{this.returnPatientName(x.idPaciente)}</td>
                                        <td key={`F${x.idAgendamento}ASD2@`}>{x.entrada}</td>
                                        <td key={`F${x.idAgendamento}SEDA$`}>{x.saida}</td>
                                        <td key={`G${x.idAgendamento}SDA$`} style={{ width: 110 }}><Button variant="link" onClick={() => this.editModal(x.idAgendamento)}><FaUserEdit /></Button>
                                            <Button variant="link" onClick={() => this.delete(x.idAgendamento)}><FaTrashAlt /></Button></td>
                                    </tr>
                                }) : null}

                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row style={{ marginTop: 10, padding: 3 }}>
                    <Col>
                        <Pagination>
                            <Pagination.Prev onClick={prevPage} />
                            <br />
                            {pages}
                            <br />
                            <Pagination.Next onClick={nextPage} />
                        </Pagination>
                    </Col>
                    <Col>
                        <Button variant="primary" href="/">
                            Voltar
                        </Button>
                        {acesso >= 2 ? <Button variant="success" style={{ marginLeft: 10 }} onClick={() => this.editModal()}>Adicionar</Button> : null}
                    </Col>
                </Row>


                <Modal show={this.state.modalShow} onHide={() => { this.setState({ modalShow: false }) }}>
                    <Modal.Header closeButton>
                        <Modal.Title>Registro</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Dropdown onSelect={(id, name) => {
                            this.setState({ nomeParceiro: name.target.text, idParceiro: id });
                            this.loadPatients(id);
                        }} style={{ marginTop: 10 }} placeholder="Parceiro" >
                            <Dropdown.Toggle id="dropdown-custom-components" style={{ width: '100%' }}>
                                {`${idParceiro} - ${nomeParceiro}`}
                            </Dropdown.Toggle>

                            <Dropdown.Menu as={CustomMenu}  >
                                {this.state.partnerList && this.state.partnerList.map(x => {
                                    return <Dropdown.Item eventKey={x.idUsuario}>{`${x.Nome} ${x.Sobrenome}`}</Dropdown.Item>
                                })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown onSelect={(id, name) => this.setState({ nomePaciente: name.target.text, idPaciente: id })} style={{ marginTop: 10 }} placeholder="Parceiro" >
                            <Dropdown.Toggle id="dropdown-custom-components" style={{ width: '100%' }}>
                                {`${idPaciente} - ${nomePaciente}`}
                            </Dropdown.Toggle>

                            <Dropdown.Menu as={CustomMenu}  >
                                {this.state.patientList && this.state.patientList.map(x => {
                                    return <Dropdown.Item eventKey={x.idPacientes
                                    }>{`${x.nome} `}</Dropdown.Item>
                                })
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                        <Form.Control style={{ marginTop: 10 }} value={this.state.entrada} type="time" placeholder="Entrada" onChange={item => this.setState({ entrada: item.target.value })} />
                        <Form.Control style={{ marginTop: 10 }} value={this.state.saida} type="time" placeholder="Saida" onChange={item => this.setState({ saida: item.target.value })} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => { this.cancelEdit() }}>
                            Voltar
                        </Button>
                        <Button variant="success" onClick={() => { this.saveOrUpdate() }}>
                            Salvar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        );
    }
}


export default Schedule;