import { request } from '../http';

const scheduleService = {
    getByFilter: (body) => {
        return request.post('/schedule/filter', body);
    },
    getReportPatient: (body) => {
        return request.post('/schedule/report', body);
    },
    getReportPartner: (body) => {
        return request.post('/pointControl/report', body);
    },
    post: (body) => {
        return request.post('/schedule', body);
    },
    patch: (body, id) => {
        return request.patch(`/schedule/${id}`, body);
    }
    ,
    delete: (id) => {
        return request.delete(`/schedule/${id}`);
    }
}

export {
    scheduleService
}